import React from "react";
import { Nav } from "react-bootstrap";
import {
  HomeSvg,
  InfoSvg,
  UsersSvg,
  CrossSvg,
  TermsSvg,
} from "../../assets/svg";

const sideDrawer = (props) => {
  let drawerClasses = "side-drawer";
  if (props.show) {
    drawerClasses = "side-drawer open";
  }
  const navItems = [
    { nav: "home", route: "home", icon: <HomeSvg theme={props.theme} /> },
    { nav: "about us", route: "about", icon: <InfoSvg theme={props.theme} /> },
    { nav: "faq", route: "faq", icon: <UsersSvg theme={props.theme} /> },
  ];

  const navLink = (nav, route, icon) => {
    return (
      <div>
        <Nav.Link
          className="d-flex align-items-center mx-5 my-4 side-menu-text"
          href={route}
          to={route}
        >
          {icon}&nbsp; &nbsp; &nbsp;{nav}
        </Nav.Link>
      </div>
    );
  };

  return (
    <nav className={drawerClasses}>
      <div
        style={{ position: "absolute", right: "30px", top: "30px" }}
        onClick={props.closeSideMenu}
      >
        <CrossSvg theme={props.theme} />
      </div>
      <Nav className="mt-5">
        <div className="mt-3">
          {navItems.map((item) => navLink(item.nav, item.route, item.icon))}
        </div>
      </Nav>
    </nav>
  );
};

export default sideDrawer;
