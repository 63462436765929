import * as React from "react";

const DownloadIconSvg = (props) => {
  return (
    <svg
      width={10}
      height={13}
      viewBox="0 0 10 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M.505 12.025H9.5c.127 0 .248.047.34.132a.483.483 0 01.047.663.503.503 0 01-.32.175L9.501 13H.505a.508.508 0 01-.344-.13.483.483 0 01-.047-.667.503.503 0 01.323-.174l.068-.004H9.5.505zM4.925.005L5.005 0c.163 0 .32.058.442.164.122.106.2.252.22.41L5.67.65V8.83l2.195-2.14a.674.674 0 01.88-.054l.063.055a.643.643 0 01.055.858l-.055.06-3.33 3.247a.674.674 0 01-.88.055l-.063-.054L1.2 7.611a.642.642 0 01-.031-.891.679.679 0 01.91-.082l.063.053 2.195 2.137V.65c0-.16.06-.313.169-.432a.672.672 0 01.42-.213L5.004 0l-.078.005z"
        fill="#fff"
      />
    </svg>
  );
};

export default DownloadIconSvg;
