import React, { useEffect, useState } from "react";
import { DropDown, Mailer } from "../../components";
import { Helmet } from "react-helmet";
import { faqData } from "../../assets/seo-content/faq";
import { FAQ_DATA } from "./faq_content";

const Faq = () => {
  const [faqs, setFaqs] = useState("");

  useEffect(() => {
    let faq_data = faqData();
    setFaqs(faq_data);
  }, []);

  return (
    <div className="mt-5" style={{ "text-align": "center" }}>
      <div
        className="d-flex flex-column align-items-center justify-content-center mt-5"
        style={{ "text-align": "center" }}
      >
        <h1>FAQ</h1>
        <p className="faq-description">Find all your answers here</p>
      </div>
      <div class="container my-5 overflowWrap" style={{ "text-align": "left" }}>
        {FAQ_DATA.map((item) => (
          <DropDown
            question={item.question}
            answer={item.answer}
            headingKey={item.headingKey}
            collapseKey={item.collapseKey}
          />
        ))}
      </div>
      <div
        className="d-flex flex-column align-items-center justify-content-center my-5"
        style={{ "text-align": "center" }}
      >
        <h1 className="mt-3">Still have questions?</h1>
        <p className="faq-description">
          Email us at
          <br />
          <Mailer
            label="clipzhubdownloader@gmail.com"
            mailto="mailto:clipzhubdownloader@gmail.com"
          />
        </p>
      </div>
      <Helmet>
        <script type="application/ld+json">{faqs}</script>
      </Helmet>
    </div>
  );
};

export default Faq;
