import React from "react";
import { DrawerToggleButton, Toggle } from "../../components";

import "./ToolBar.css";

const ToolBar = (props) => (
  <header className="toolbar">
    <nav className="toolbar__navigation">
      <div className="toolbar__toggle-button">
        <div className="d-flex flex-row">
          <Toggle theme={props.theme} toggleTheme={props.toggleTheme} />
          <div className="mx-2" />
          <DrawerToggleButton click={props.drawerClickHandler} />
        </div>
      </div>
    </nav>
  </header>
);

export default ToolBar;
