export const faqData = () => {
  let data = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    mainEntity: [
      {
        "@type": "Question",
        name: "What makes Clipzhub different from others?\n",
        acceptedAnswer: {
          "@type": "Answer",
          text: "Clipzhub has been created by gamers for gamers ensuring a smooth and seamless experience while securing the Twitch clip downloads. Clipzhub ensures a much faster download of the online twitch clips in two easy steps while autodetecting the pasted URL and finding the right thumbnail and title of the video clip. Unlike other websites, Clipzhub does not redirect you to numerous tabs before the twitch clip downloads. Moreover, Clipzhub can be accessed through mobile phones without any compromise with the features of the platform and you can switch between light mode and dark mode easily. Clipzhub also helps you choose the right format to download the desired Twitch clip, retaining the video clip’s original quality. We get that entertainment is a priority, so why get bored while downloading the cool content? Clipzhub’s design is much more interactive and thoughtful keeping in mind that you should get the smoothest experience that you deserve! \n",
        },
      },
      {
        "@type": "Question",
        name: "How to use the Clipzhub Twitch clip downloader?\n",
        acceptedAnswer: {
          "@type": "Answer",
          text: "Our Twitch Downloader has simplified the downloading experience and has made the work hassle-free :) Follow these two easy steps mentioned below to download your Twitch clip:\n\nSimply insert the URL you want to download in the above search bar \nand \nWait for a few seconds to auto-download your Twitch clip. \n\nThere you go, you have your downloaded clip in no time! \n",
        },
      },
      {
        "@type": "Question",
        name: "Is it safe to download twitch clips from Clipzhub?\n\n",
        acceptedAnswer: {
          "@type": "Answer",
          text: "Absolutely, Clipzhub provides a platform to a huge audience enabling them to download any Twitch clip for their personal use which can be to just watch the Twitch clip offline or save it in your own created offline playlist or any other personal use. However, if you want to use any Twitch clip for commercial use, professional use, streaming purposes or sharing the Twitch clip with a wide range of audiences, you need to take permission from the Twitch clip owner. If you fail to do so, you infringe the intellectual property rights of the owner and you might get in some serious trouble.\nWe highly recommend you go through the guidelines and terms and policies provided by Twitch.Tv before using any other streamers’ Twitch clips or any other content that does not align with Twitch.Tv’s guidelines. \n",
        },
      },
      {
        "@type": "Question",
        name: "Can I use Clipzhub to download Twitch clips in Phone? \n",
        acceptedAnswer: {
          "@type": "Answer",
          text: "Absolutely. Our designers have specially designed a Mobile mode for you so that your Twitch clip downloading experience is smooth, portable and device friendly. Our mobile mode supports all mobile devices and your experience is ensured to be uncompromising. \n",
        },
      },
      {
        "@type": "Question",
        name: "What are the main features of the Twitch clip downloader Clipzhub?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "Clipzhub is essentially a twitch downloader platform that caters to your twitch clip requisites. Clipzhub currently supports only Twitch clip downloading but in future will soon launch tools to download content from other social media platforms like Facebook, VK, Twitter, Instagram, Reddit, Tik-Tok, etc. \nWhile you paste the URL you want to download, our tool automatically tracks down the video for you and makes the Twitch clip offline in a few seconds. In addition to this, our tool also tracks the thumbnail and the title of the video along with the name of the channel for your ease. This helps you directly save the video by copying the required data from Clipzhub.\n Our Twitch clip downloader comes in two different themes - light mode and dark mode. While you switch the theme from one to another, Clipzhub saves the history. You can also download the twitch clip on your phone. Clipzhub also gives you an option of directly copying the auto-searching Twitch link to share with your audience.",
        },
      },
      {
        "@type": "Question",
        name: "Is Clipzhub free to use?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "Absolutely, we don’t believe in hogging money from our users for the simplest of services, our online twitch clip saver aka Clipzhub is absolutely free and there are no hidden charges. We believe everyone has a right to entertainment free of cost and we pledge to entertain you and serve you for a lifetime! \nNow you can download as many Twitch clips you wish to, cost-free using our very own platform Clipzhub the only Twitch clip downloader you want. Having said that, go check out our auto-searching features and get started with your first free offline Twitch clip if you haven’t yet!",
        },
      },
    ],
  };
  return JSON.stringify(data);
};
