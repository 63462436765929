import React from "react";
import { BrowserRouter, Link } from "react-router-dom";
const Mailer = ({ mailto, label }) => {
  return (
    <BrowserRouter>
      <Link
        className="link"
        to="#"
        onClick={(e) => {
          window.location = mailto;
          e.preventDefault();
        }}
      >
        {label}
      </Link>
    </BrowserRouter>
  );
};

export default Mailer;
