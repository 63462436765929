export const HOME_CONTENT = [
  {
    headingKey: 1,
    collapseKey: "collapseOne",
    question: "What is Clipzhub?",
    answer: (
      <p>
        Clipzhub is your one-stop solution for all your twitch clip downloading
        needs. It is hands down the best twitch clip downloader in 2021.
        Clipzhub allows you to download your favourite twitch clips from your
        favourite streamers in two simple, easy and fast steps. Now you can
        download as many twitch clips as you want in the blink of an eye!
        <br />
        <br />
        Clipzhub has been designed specifically to make the user experience
        smooth and effortless. Providing fast and easy access to twitch clips
        being the absolute priority, Clipzhub makes the whole downloading
        process super short and seamless. You can get your free offline video in
        a few seconds if not sooner without compromising with the desired
        quality of the video or the audio of the Twitch clip. Clipzhub is easily
        the most optimal online twitch clip saver by creating and providing a
        secure and smooth platform for all your twitch clip needs. You can
        download your videos in mp4 format in a few seconds, how awesome is
        that?
      </p>
    ),
  },
  {
    headingKey: 2,
    collapseKey: "collapseTwo",
    question:
      "What are the main features of the Twitch clip downloader Clipzhub?",
    answer: (
      <p>
        Clipzhub is essentially a twitch video downloader platform that caters
        to your twitch clip requisites. Clipzhub currently supports only Twitch
        clip downloading but in future will soon launch tools to download
        content from other social media platforms like Facebook, VK, Twitter,
        Instagram, Reddit, TikTok, etc.
        <br />
        <br />
        While you paste the URL you want to download, our tool automatically
        tracks down the video for you and makes the Twitch clip offline in a few
        seconds. In addition to this, our tool also tracks the thumbnail and the
        title of the video along with the name of the channel for your ease.
        This helps you directly save the video by copying the required data from
        Clipzhub.
        <br />
        <br />
        Our Twitch clip downloader comes in two different themes - light mode
        and dark mode. While you switch the theme from one to another, Clipzhub
        saves the history. Our Twitch video downloader is accessible in mobile
        view as well. Clipzhub also gives you an option of directly copying the
        auto-searching Twitch link to share with your audience. You can look
        through our step-wise demonstration below on how to download Twitch
        clips.
      </p>
    ),
  },
  {
    headingKey: 3,
    collapseKey: "collapseThree",
    question: "What makes Clipzhub different from others?",
    answer: (
      <p>
        Clipzhub has been created by gamers for gamers ensuring a smooth and
        seamless experience while securing the Twitch clip downloads. Our Twitch
        stream downloader ensures a much faster download of the online twitch
        clips in two easy steps while autodetecting the pasted URL and finding
        the right thumbnail and title of the video clip.
        <br />
        <br />
        Clipzhub does not redirect you to numerous tabs before the twitch clip
        downloads. Moreover, Clipzhub can be accessed in mobile view. Clipzhub
        also lets you switch between light mode and dark mode in one click.
        Clipzhub’s design is much more interactive and thoughtful keeping in
        mind the smoothest user experience!
      </p>
    ),
  },
  {
    headingKey: 4,
    collapseKey: "collapseFour",
    question: "How to download Twitch clips using Clipzhub?",
    answer: (
      <p>
        Our Twitch video downloader has simplified the downloading experience
        and has made the work hassle-free. Follow these two easy steps mentioned
        below to download your Twitch clip:
        <br />
        <br />
        &nbsp;&nbsp;&nbsp;a. Simply insert the URL you want to download in the
        above search bar
        <br />
        &nbsp;&nbsp;&nbsp;b. Wait for a few seconds to auto-search your Twitch
        clip and click on the “Download” button.
        <br />
        <br />
        There you go, you have your downloaded clip in no time!
      </p>
    ),
  },
  {
    headingKey: 5,
    collapseKey: "collapseFive",
    question: "What is Twitch?",
    answer: (
      <p>
        Twitch is an all in one platform mainly famous for streaming gaming
        videos online. Twitch gained its popularity when it outdid its
        counterpart. Twitch started its services back in 2011 and is one of the
        leading streaming platforms in America till date. In addition to
        streaming video games, Twitch is famously used to watch esports clips
        and competitions. Twitch also broadcasts music videos, art videos,
        podcasts, cooking and many more. Twitch recently launched IRL streams
        and we are amazed by every bit of it. Twitch gained so much popularity
        that Amazon went ahead and bought it for several million dollars. Twitch
        caters to millions of viewers every month and helps gamers and streamers
        create their full-length careers completely online and by doing so
        Twitch motivates young gamers to process the whole new possibility of
        making careers in gaming and streaming with minimalism and we are in
        total support of that. The introduction of Twitch to the internet took
        the video streaming business to the sky, helping the streamers monetise
        their channels through several ad partnerships and commissions on sales
        of the games that the streamers stream.
        <br />
        <br />
        Here are some fun stats about Twitch, we bet most of you didn’t know.
        The most used and popular feature of Twitch is not streaming but is
        “Just Chatting”. The average number of users engaging with this feature
        outnumbers the viewers watching Twitch’s online gaming streams. The
        platform hosts a convention called TwitchCon annually at least once and
        the traction it attracts is mind-boggling. The convention helps the
        community to meet and greet and improve their Brand promotions and
        engagements. Twitch caters to but is not limited to young viewers and
        over 50% of the users are under the age of 35. The most popular game in
        Twitch has been League of Legends and Twitch experiences the most user
        engagement on Sundays, now we know where to find you on those lazy
        Sunday afternoons!
        <br />
        <br />
        As Twitch.tv is gaining popularity more and more, creating an ideal
        user-friendly Twitch clip to mp4 converting platform is more than an
        accessory. Our versatile Twitch stream downloader helps you use and
        share offline Twitch clips instantly. If you want to know more on how to
        download Twitch clips using our Twitch stream downloader and how does
        our Twitch to mp4 converter works, check out our frequently asked
        questions page or contact us at the undersigned mail address. Also,
        check out our about us page to know more about our Twitch to mp4
        converter!
      </p>
    ),
  },
];
