import * as React from "react";

const crossSvg = (props) => {
  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M13.997 2.005L2.007 13.993M14 14L2 2"
        stroke={props.theme === "dark" ? "#fff" : "#262626"}
        strokeWidth={3}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default crossSvg;
