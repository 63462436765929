import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../styles/globalStyles";
import { copyButton, downloadButton } from "../../assets/images/index";
import { CopyToClipboard } from "react-copy-to-clipboard";
import toast, { Toaster } from "react-hot-toast";
import Dotdotdot from "react-dotdotdot";
import { copyTwitchLinkEvent } from "../../googleAnalytics";
const SearchData = (props) => {
  const onPressCopy = () => {
    copyTwitchLinkEvent();
    toast.success("Sucessfully copied!", {
      duration: 2000,
      position: "top-center",

      style: {
        marginTop: "30px",
        borderRadius: "10px",
        background: "#333",
        color: "#fff",
      },

      iconTheme: {
        primary: "#000",
        secondary: "#fff",
      },
    });
  };

  return (
    <div className="searchedDataMain">
      <img
        src={props.clipDetails.thumbnail_url}
        className="searchedDataImg"
        alt="Clipzhub search data"
      />
      <div className="relativeDiv">
        <div>
          <div className="videoTitle">
            <Dotdotdot clamp={2}>
              <h3>{props.clipDetails.title}</h3>
            </Dotdotdot>
          </div>
          <h4 className="channelName">{props.clipDetails.broadcaster_name}</h4>
        </div>
        <div className="searchedDataBtns d-flex flex-row align-items-center justify-content-center">
          <div className="d-flex flex-row align-items-center">
            <CopyToClipboard
              text={props.clipDetails.downloadUrl}
              onCopy={onPressCopy}
            >
              <div className="svg-btn">
                <img src={copyButton} alt={"Clipzhub copy url"}></img>
              </div>
            </CopyToClipboard>
            <div
              className="svg-btn"
              style={{ marginLeft: "10px" }}
              onClick={props.onDownloadClick}
            >
              <img src={downloadButton} alt="Clipzhub download button" />
            </div>
          </div>
        </div>
      </div>
      <Toaster />
    </div>
  );
};
export default SearchData;
