import React from "react";

const DropDown = (props) => {
  return (
    <div className="my-4">
      <div class="accordion accordion-flush">
        <div class="accordion-item">
          <h2 class="accordion-header" id={props.headingKey}>
            <button
              class="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target={`#${props.collapseKey}`}
              aria-expanded="false"
              aria-controls={props.collapseKey}
            >
              {props.question}
            </button>
          </h2>
          <div
            id={props.collapseKey}
            class="accordion-collapse collapse"
            aria-labelledby={props.headingKey}
          >
            <div class="accordion-body">{props.answer}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DropDown;
