import "./App.css";
import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Provider } from "react-redux";
import routes from "./router/routes";
import { ConnectedRouter } from "connected-react-router";
import { createBrowserHistory } from "history";
import initStore from "./redux/store";
import { renderRoutes } from "react-router-config";
import { Header, Footer } from "./components";

import { useDarkMode } from "./styles/useDarkMode";
import { GlobalStyles, lightTheme, darkTheme } from "./styles/globalStyles";
import { ThemeProvider } from "styled-components";
import ReactGA from "react-ga4";
require("dotenv").config();

const App = () => {
  const [theme, toggleTheme] = useDarkMode();
  const themeMode = theme === "light" ? lightTheme : darkTheme;

  const history = createBrowserHistory();
  const initialState = {};
  const store = initStore(initialState, history);

  ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID);

  ReactGA.send({
    hitType: "pageview",
    page: window.location.pathname + window.location.search,
  });

  return (
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <ThemeProvider theme={themeMode}>
          <>
            <GlobalStyles />
            <Header theme={theme} toggleTheme={toggleTheme} />
            {renderRoutes(routes)}
            <div className="my-5" style={{ "text-align": "center" }}>
              <p>This site is in no way affiliated with Twitch</p>
            </div>
            <Footer />
          </>
        </ThemeProvider>
      </ConnectedRouter>
    </Provider>
  );
};

export default App;
