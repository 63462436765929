/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import { astronaut } from "../../assets/images";
import { Mailer } from "../../components";

const About = (props) => {
  return (
    <div className="mt-5" style={{ "text-align": "center" }}>
      <div
        className="d-flex flex-column align-items-center justify-content-center mt-5"
        style={{ "text-align": "center" }}
      >
        <h1>About Us</h1>
      </div>
      <div class="my-5" style={{ "text-align": "center" }}>
        <img
          className="about-us-image"
          src={astronaut}
          alt="Introdution of Clipzhub"
        />
        <p className="about-description mt-5" style={{ "text-align": "left" }}>
          Clipzhub is a result of critical thinking about Twitch clip downloads
          and auto-searching the clips in just a few seconds hassle-free,
          keeping in mind the whole seamless user experience.
          <br />
          <br />
          Clipzhub has been thoughtfully created and designed keeping every
          detail in mind to ease out and smoothen the whole downloading
          experience for our users. Clipzhub is accessible through mobile phones
          too. Our mobile view is sleek and the design created is user-friendly.
          <br />
          <br />
          You can find the below-added features in the website which are ideated
          and created for a speedy user experience:
          <br />
          <br />
          1. You can copy the download link and share it with your audience.
          <br />
          2. When you paste the Twitch clip URL on the search bar, we
          auto-search the clip and provide you with the title and thumbnail of
          the clip along with the name of the streamer.
          <br />
          3. You can switch between light mode and dark mode.
          <br />
          <br />
          In near future, we will also launch content downloader for other
          social media platforms like Reddit, Instagram, Facebook, VK, Twitter,
          Youtube and more.
        </p>
      </div>
      <div
        className="d-flex flex-column align-items-center justify-content-center my-5"
        style={{ "text-align": "center" }}
      >
        <p className="faq-description">
          Email us at
          <br />
          <Mailer
            label="clipzhubdownloader@gmail.com"
            mailto="mailto:clipzhubdownloader@gmail.com"
          />
        </p>
      </div>
    </div>
  );
};

export default About;
