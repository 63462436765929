import * as React from "react";

const InfoSvg = (props) => {
  return (
    <svg
      width={20}
      height={20}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        clipRule="evenodd"
        d="M14.334.75H5.665C2.644.75.75 2.889.75 5.916v8.168c0 3.027 1.885 5.166 4.915 5.166h8.668c3.031 0 4.917-2.139 4.917-5.166V5.916c0-3.027-1.886-5.166-4.916-5.166z"
        stroke={props.theme === "dark" ? "#fff" : "#262626"}
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.995 14v-4"
        stroke={props.theme === "dark" ? "#fff" : "#262626"}
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.99 6.204H10"
        stroke={props.theme === "dark" ? "#fff" : "#262626"}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default InfoSvg;
