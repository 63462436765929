import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Alert } from "react-bootstrap";
import { danger } from "../../assets/images";

const ErrorAlert = () => {
  return (
    <div className="center">
      <Alert className="errorCenter center" variant={"danger"}>
        <div>
          <img
            src={danger}
            width="30"
            height="30"
            style={{ marginLeft: "12px" }}
            alt="Clipzhub Error message"
          ></img>
        </div>
        <div className="alertText">
          Invalid Twitch link. Check your link and try again.
        </div>
      </Alert>
    </div>
  );
};
export default ErrorAlert;
