/* eslint-disable import/no-anonymous-default-export */
export default {
  GET_CLIP_DETAILS: {
    START: "app.get_clip_details:start",
    SUCCESS: "app.get_clip_details:success",
    FAIL: "app.get_clip_details:fail",
  },

  RESET_CLIP_DETAILS: "app.reset_clip_details",

  CHANGE_THEME: "app.change_theme",
};
