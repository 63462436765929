/* eslint-disable jsx-a11y/alt-text */
import React from "react";

const Terms = (props) => {
  return (
    <div className="mt-5 mb-5 center" style={{ "text-align": "center" }}>
      <div
        className="d-flex align-items-center justify-content-center mt-5 mb-5"
        style={{
          textAlign: "center",
        }}
      >
        <h1
          style={{
            marginBottom: "50",
            fontFamily: "Montserrat",
            fontWeight: "600",
            fontSize: "32px",
          }}
        >
          {" "}
          Terms And Conditions
        </h1>
      </div>
      <div style={{ "text-align": "left", marginBottom: "50" }}>
        <p className="termsPrivacy-description mt-5 mb-5">
          <h4 className="termsHeading">1. Terms</h4>
          By accessing this Website, accessible from https://clipzhub.xyz, you
          are agreeing to be bound by these Website Terms and Conditions of Use
          and agree that you are responsible for the agreement with any
          applicable local laws. If you disagree with any of these terms, you
          are prohibited from accessing this site. The materials contained in
          this Website are protected by copyright and trade mark law.
          <br />
          <br />
          <h4 className="termsHeading">2. Use License</h4>
          Permission is granted to temporarily download one copy of the
          materials on Clipzhub's Website for personal, non-commercial
          transitory viewing only. This is the grant of a license, not a
          transfer of title, and under this license you may not:
          <br />
          modify or copy the materials;
          <br /> use the materials for any commercial purpose or for any public
          display;
          <br />
          attempt to reverse engineer any software contained on Clipzhub's
          Website; remove any copyright or other proprietary notations from the
          materials; or
          <br /> transferring the materials to another person or "mirror" the
          materials on any other server.
          <br />
          This will let Clipzhub to terminate upon violations of any of these
          restrictions. Upon termination, your viewing right will also be
          terminated and you should destroy any downloaded materials in your
          possession whether it is printed or electronic format. These Terms of
          Service has been created with the help of the Terms Of Service
          Generator.
          <br />
          <br />
          <h4 className="termsHeading">3. Disclaimer</h4>
          All the materials on Clipzhub’s Website are provided "as is". Clipzhub
          makes no warranties, may it be expressed or implied, therefore negates
          all other warranties. Furthermore, Clipzhub does not make any
          representations concerning the accuracy or reliability of the use of
          the materials on its Website or otherwise relating to such materials
          or any sites linked to this Website.
          <br />
          <br />
          <h4 className="termsHeading">4. Limitations</h4>
          Clipzhub or its suppliers will not be hold accountable for any damages
          that will arise with the use or inability to use the materials on
          Clipzhub’s Website, even if Clipzhub or an authorize representative of
          this Website has been notified, orally or written, of the possibility
          of such damage. Some jurisdiction does not allow limitations on
          implied warranties or limitations of liability for incidental damages,
          these limitations may not apply to you.
          <br />
          <br />
          <h4 className="termsHeading">5. Revisions and Errata</h4>
          The materials appearing on Clipzhub’s Website may include technical,
          typographical, or photographic errors. Clipzhub will not promise that
          any of the materials in this Website are accurate, complete, or
          current. Clipzhub may change the materials contained on its Website at
          any time without notice. Clipzhub does not make any commitment to
          update the materials.
          <br />
          <br />
          <h4 className="termsHeading">6. Links</h4>
          Clipzhub has not reviewed all of the sites linked to its Website and
          is not responsible for the contents of any such linked site. The
          presence of any link does not imply endorsement by Clipzhub of the
          site. The use of any linked website is at the user’s own risk.
          <br />
          <br />
          <h4 className="termsHeading">7. Site Terms of Use Modifications</h4>
          Clipzhub may revise these Terms of Use for its Website at any time
          without prior notice. By using this Website, you are agreeing to be
          bound by the current version of these Terms and Conditions of Use.
          <br />
          <br />
          <h4 className="termsHeading">8. Your Privacy</h4>
          Please read our Privacy Policy.
          <br />
          <br />
          <h4 className="termsHeading">9. Governing Law</h4>
          Any claim related to Clipzhub's Website shall be governed by the laws
          of jo without regards to its conflict of law provisions.
        </p>
      </div>
    </div>
  );
};

export default Terms;
