import endpoints from "./endpoints";

import HomeScreen from "../screens/homescreen";
import About from "../screens/about";
import Faq from "../screens/faq";
import Terms from "../screens/terms";
import PrivacyPolicy from "../screens/privacyPolicy";

const routes = [
  {
    path: endpoints.about,
    component: About,
    exact: true,
  },
  {
    path: endpoints.faq,
    component: Faq,
    exact: true,
  },
  {
    path: endpoints.privacyPolicy,
    component: PrivacyPolicy,
    exact: true,
  },
  {
    path: endpoints.terms,
    component: Terms,
    exact: true,
  },
  {
    path: endpoints.home,
    component: HomeScreen,
  },
];

export default routes;
