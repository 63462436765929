import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { CardGroup, Card } from "react-bootstrap";
import "../../styles/globalStyles";
import {
  lightStep1,
  lightStep2,
  lightStep3,
  darkStep1,
  darkStep2,
  darkStep3,
} from "../../assets/images";

const Cards = (props) => {
  return (
    <div className="my-5">
      <h3 className="text-center">
        How to use <span style={{ fontWeight: "600" }}>Clipzhub</span>
      </h3>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div>
          <CardGroup className="mt-3">
            <Card className="mx-2">
              <Card.Body
                className="card align-items-center"
                style={{ width: "20rem" }}
              >
                <img
                  src={props.theme === "dark" ? darkStep1 : lightStep1}
                  alt="Clipzhub paste link in search bar"
                />

                <Card.Title className="text-center mt-3">Step 1</Card.Title>
                <Card.Text className="text-center">
                  Paste the link in{" "}
                  <span className="text-red">
                    <br />
                    search bar
                  </span>
                </Card.Text>
              </Card.Body>
            </Card>
            <Card className="mx-2">
              <Card.Body
                className="card align-items-center"
                style={{ width: "20rem" }}
              >
                <img
                  src={props.theme === "dark" ? darkStep2 : lightStep2}
                  alt="Clipzhub wait for processing"
                />
                <Card.Title className="text-center mt-3">Step 2</Card.Title>
                <Card.Text className="text-center">
                  Wait for the processing,
                  <br />
                  Usually a<span className="text-red"> split second</span>
                </Card.Text>
              </Card.Body>
            </Card>
            <Card className="mx-2">
              <Card.Body
                className="card align-items-center"
                style={{ width: "20rem" }}
              >
                <img
                  src={props.theme === "dark" ? darkStep3 : lightStep3}
                  alt="Clipzhub click download"
                />
                <Card.Title className="text-center mt-3">Step 3</Card.Title>
                <Card.Text className="text-center">
                  Just click
                  <span className="text-red">
                    <br />
                    download
                  </span>
                </Card.Text>
              </Card.Body>
            </Card>
          </CardGroup>
        </div>
      </div>
    </div>
  );
};

export default Cards;
