import { createStore, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import createSagaMiddleware from "redux-saga";
import createReducer from "./reducers";
import sagas from "./sagas";

export default function initStore(initialState = {}, history) {
  const sagaMiddleware = createSagaMiddleware();

  const allMiddlewares = [sagaMiddleware];

  const enhancer = composeWithDevTools(applyMiddleware(...allMiddlewares));

  const store = createStore(createReducer(history), initialState, enhancer);
  sagas.forEach((saga) => sagaMiddleware.run(saga));
  return store;
}
