import { createGlobalStyle } from "styled-components";

export const GlobalStyles = createGlobalStyle`
  html,
  body {
    background: ${({ theme }) => theme.body};
    transition: all .3s linear;
    width: 100%;
    position: relative;
    min-height: 100%;
    overflow-x: hidden;
  }

  html {
    overflow-y: scroll;
  }

  ::-webkit-scrollbar {
    width: 7px;
    border-radius: 50px;
  }

  ::-webkit-scrollbar-track {
    background: ${({ theme }) => theme.body};
  }

  ::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.text};
    // background-image: linear-gradient( #F45C43 , #EB3349);
    border-radius: 25px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background-image: linear-gradient( #EB3349, #F45C43);
    border-radius: 25px;
  }
  
  h1 {
    color: ${({ theme }) => theme.text};
    font-family: 'Montserrat-Bold', sans-serif;
    font-weight: bold;
    transition: all .3s linear;
  }

  h2 {
    color: ${({ theme }) => theme.text};
    transition: all .3s linear;
    font-family: 'Montserrat-SemiBold', sans-serif;
  }

  h3 {
    color: ${({ theme }) => theme.text};
    transition: all .3s linear;
    font-family: 'Montserrat', sans-serif;
  }

  h4 {
    color: ${({ theme }) => theme.text};
    transition: all .3s linear;
    font-family: 'Montserrat', sans-serif;
  }

  p {
    color: ${({ theme }) => theme.text};
    transition: all .3s linear;
    font-family: 'Montserrat', sans-serif;
  }

  .error-bold-text {
    font-family: 'Montserrat-Bold', sans-serif;
    font-weight: bold;
    transition: all .3s linear;
  }

  .overflowWrap {
    overflow-wrap: break-word;
  }

  .navbar-expand-lg {
    background: ${({ theme }) => theme.navbarBg};
    transition: all .3s linear;
  }

  .navbar-light .navbar-brand {
    background: linear-gradient( #F45C43 , #EB3349);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 1.5rem;
    font-family: 'Montserrat-Bold', sans-serif;
    font-weight: bold;
  }

  .navbar-light .navbar-nav .nav-link {
    color: ${({ theme }) => theme.text};
    font-size: 1.25rem;
    font-family: 'Montserrat', sans-serif;
    transition: all .3s linear;
  }

  .navbar-nav .nav-link {
    padding: 0px;
  }

  .navbar-light .navbar-nav .nav-link.active, .navbar-light .navbar-nav .show>.nav-link {
    color: white;
  }

  .navbar-light .navbar-nav .nav-link:focus, .navbar-light .navbar-nav .nav-link:hover {
    color: grey;
  }

  .navbar-light .navbar-toggler {
    background: white;
  }

  .card {
    background-color: transparent;
    border-color: transparent;
  }

  .card-body {
    flex: 1 1 auto;
    padding: 1rem 1rem;
  }

  .card-text {
    color: ${({ theme }) => theme.text};
    transition: all .3s linear;
    font-size: 1rem;
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
  }

  .card-title {
    color: ${({ theme }) => theme.text};
    transition: all .3s linear;
    font-size: 1.25rem;
    font-family: 'Montserrat-Bold', sans-serif;
    font-weight: 600;
  }

  .card-group>.card:not(:first-child) {
    border: none;
  }

  .card-group>.card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border: none;
  }

  .title-text{
    font-size: 2rem;
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
  }

  .subTitle-text{
    font-size: 1.75rem;
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
  }

  .text {
    text-align: center;
    color: ${({ theme }) => theme.text};
    transition: all .3s linear;
  }
  
  .cardBackground {
    padding-top: 1%;
    padding-bottom: 1%;
    background-color: ${({ theme }) => theme.card};
    transition: all .3s linear;
  }

  .videoTitle {
    font-size: 28px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    font-color: #FBFBFB;
    max-width: 400px;
  }
 
  .channelName {
    font-size: 22px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    font-color: #FBFBFB
  }
  
  .center {
    margin: auto;
    width: 70%;
    padding: 10px;
  }
  
  .cardMain {
    margin: auto;
    padding: 10px;
    margin-top: 5%;
    justify-content: space-between;
    display: flex;
    flex-direction: row;
  }

  .text-red {
    background: linear-gradient( #F45C43 , #EB3349);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: 600;
  }
  
  mark.blue{
    color: 'red';
    background: none;
  }

  @media (max-width: 960px) {
    .cardMain {
      margin: auto;
      width: 70%;
      padding: 10px;
      flex: 1 !important;
      display: flex;
      flex-direction: column;
    }

    .card-group {
      flex-direction: column;
    }
    
    .cardSize {
      width: "18rem";
    }
  }

  .searchedDataMain{
      display: flex;
      justify-content: center;
      margin-top: 30px;
  }

  .searchedDataImg{
    margin-right: 15px;
    height: 200px;
    width: 310px;
    border-radius: 19px;
  }

  .searchedDataBtns{
    position: absolute;
    bottom: 0px;
  }

  .relativeDiv{
    position: relative;
  }

  @media (max-width: 960px) {
    .searchedDataMain{
      margin-top: 30px;
      display: flex;
      flex-direction: column;
      align-items: center;
  }

    .searchedDataImg{
      margin-right: 0px;
      height: 200px;
      width: 310px;
      border-radius: 19px;
  }

    .searchedDataBtns{
      margin-top: 20.5px;
      position: static;
      bottom: 100px;
    }
    
    .relativeDiv{
      position: static;
    }

    .videoTitle {
      margin-top: 21px;
      text-align: center;
      font-size: 28px;
      font-family: 'Montserrat', sans-serif;
      font-weight: 600;
      font-color: #FBFBFB;
    }

    .channelName {
      margin-top: 12px;
      text-align: center;
      font-size: 22px;
      font-family: 'Montserrat', sans-serif;
      font-weight: 400;
      font-color: #FBFBFB
    }
  }

  .cardSize{
    flex: 1;
      justify-content: space-between;
      display: flex;
    width: "18rem";
  }

  @media (max-width: 769px) {
    .toggle-theme-button {
      display: none !important;
    }
  }

  .space {
    padding-top: 5%;
  }
  
  .space5 {
    padding-top: 5%;
  }

  .side-drawer {
    height: 100%;
    background: ${({ theme }) => theme.sideDrawer};
    box-shadow: 1px 0px 7px rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    right: 0;
    width: 85%;
    max-width: 400px;
    z-index: 400;
    transform: translateX(100%);
    transition: transform 0.3s ease-out;
  }
  
  .side-drawer.open {
    transform: translateX(0);
  }

  @media (min-width: 769px) {
    .side-drawer {
        display: none;
    }
  }

  .toggle-button__line {
    width: 1.875rem;
    height: 2px;
    background: ${({ theme }) => theme.sideDrawerIcon};
    transition: transform 0.3s ease-out;
  }

  .faq-description {
    font-size: 1.375rem;
    font-family: 'Montserrat', sans-serif;
  }


  .accordion-item,
  .accordion-body,
  .accordion-item:last-of-type .accordion-button.collapsed,
  .accordion-flush .accordion-item .accordion-button {
    background: transparent;
    border:1px solid;
    font-family: Montserrat;
    font-size: 22px;
    font-weight: 600;
    width: 100%; 
    border-color: ${({ theme }) => theme.text};
    transition: all .3s linear;
    border-radius: 6px
  }

  .svg-btn{
    cursor: pointer;
  }

  .accordion-item, .accordion-item:last-of-type .accordion-button.collapsed, .accordion-flush .accordion-item .accordion-button {
    color: ${({ theme }) => theme.text};
    transition: all .3s linear;
  }

  .accordion-button:not(.collapsed)::after {
    background-image: url("data:image/svg+xml, ${({ theme }) =>
      theme.crossImg}")
  }

  .accordion-button::after {
    background-image: url("data:image/svg+xml, ${({ theme }) =>
      theme.crossImg}")
  }

  .accordion-button:focus {
    outline: none;
    box-shadow: none;
  }


  
  @media (max-width: 768px) {
    .about-us-image {
      width:230px;
      height:230px;
      overflow:hidden;
    }
  }

  .termsPrivacy-description{
    font-size: 14px;
    font-weight: 400;
    font-family: 'Montserrat', sans-serif;
  }

  .about-description {
    padding-left: 300px;
    padding-right: 300px;
    padding-bottom: 20px;
    font-size: 1.375rem;
    font-weight: 400;
    font-family: 'Montserrat', sans-serif;
  }
  
  .termsHeading{
    font-size: 14px;
    font-weight: 600;
  }
  .privacyPolicyHeading{
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 10;
  }
  
  @media (max-width: 960px) {
    .terms&Privacy-description{
      font-size: 14px;
      font-weight: 400;
      font-family: 'Montserrat', sans-serif;
    }
  }

  @media (max-width: 1080px) {
    .about-description {
      padding-left: 100px;
      padding-right: 100px;
      padding-bottom: 20px;
      font-size: 1.375rem;
      font-family: 'Montserrat', sans-serif;
    }
  }
  
  .placeholderText{
    font-family: Montserrat;
    font-weight: 400;
    font-size: 22px;
  }

  @media (max-width: 768px) {
    .about-description {
      padding-left: 50px;
      padding-right: 50px;
      padding-bottom: 20px;
      font-size: 1rem;
      font-family: 'Montserrat', sans-serif;
    }
  }

  .input-download-btn {
    background: linear-gradient( #F45C43 , #EB3349);
    border-top-right-radius: 17px;
    border-bottom-right-radius: 17px;
    color: white;
    padding: 0.5rem 1.5rem;
    font-size: 1rem;
    cursor: pointer;
    outline: none;
    border: none;
    transition: all .3s linear;
  }

  .input-svg {
    position: absolute;
    top: 22px;
    left: 25px;
    z-index: 200;
  }
  
  #input-container > input {
    padding-left: 72px;
    padding-right: 50px;
    background: ${({ theme }) => theme.urlBg};
    border-top-left-radius: 17px;
    border-bottom-left-radius: 17px;
    z-index: 100;
    padding-top: 15px;
    padding-bottom: 15px;
    border: 0px solid;
    color: ${({ theme }) => theme.text};
    transition: all .3s linear;
  }

  .seperator {
    position: absolute;
    top: 20px;
    left: 72px;
    z-index: 200;
    height: 35px;
    width: 1.5px;
    border-radius: 6px;
    background-color: #595959;
  }

  .input-cross-svg {
    position: absolute;
    top: 30px;
    right: 150px;
    z-index: 200;
    cursor: pointer;
  }

  .alertText{
    width: auto;
    margin-left: 10px;
  }

  @media (max-width: 768px) {
    .input-cross-svg {
      display: none;
    }

    .alertText{
      margin-left: 10px;
      width: 250px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .btn-text-download {
      display: none;
    }

    .center {
      margin: auto;
      width: 90%;
      padding: 10px;
    }
    
    #input-container > input {
      padding-left: 72px;
      padding-right: 10px;
      background: ${({ theme }) => theme.urlBg};
      border-top-left-radius: 17px;
      border-bottom-left-radius: 17px;
      z-index: 100;
      padding-top: 15px;
      padding-bottom: 15px;
      border: 0px solid;
      color: ${({ theme }) => theme.text};
      transition: all .3s linear;
    }
  }

  .accordion-item, .accordion-body{
    font-weight: 400;
    border: 0px;
  }

  .accordion-button{
    border: 1px solid;
  }

  @media (min-width: 768px) {
    .btn-text-icon {
      display: none;
    }
  }

  .errorCenter{
    border-radius: 17px;
    height: 64px;
    font-family: Montserrat;
    font-size: 15px;
    color: #842029;
    display: flex;
    align-items: center;
    justify-content: left;
    flex-direction: row;
    width: 90%;
  }

  .btn:focus {
    outline: none;
    box-shadow: none;
  }

  #input-container > input:focus {
    outline: none;
    box-shadow: none;
  }

  .hidden-content {
    display: none;
  }

  .link {
    color: ${({ theme }) => theme.primary};
    transition: all .3s linear;
  }

  .link:hover {
    color: ${({ theme }) => theme.text};
    transition: all .3s linear;
  }

  @media (max-width: 768px) {
    .link {
      color: ${({ theme }) => theme.primary};
      transition: all .3s linear;
      font-size: 16px
    }  
  }
`;

export const lightTheme = {
  body: "#F3F3F3",
  text: "#121212",
  primary: "#F45C43",
  card: "#f2f2f2",
  button: "#F45C43",
  navbarBg: "#fff",
  sideDrawer: "#E1E1E1",
  sideDrawerIcon: "#262626",
  urlBg: "#E2E2E2",
  crossImg:
    "%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M19 8.5L12 15.5L5 8.5' stroke='black' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A",
};

export const darkTheme = {
  body: "#1B1B1B",
  text: "#fBfBfB",
  primary: "#F45C43",
  card: "#1B1B1B",
  button: "#F45C43",
  navbarBg: "#1B1B1B",
  sideDrawer: "#262626",
  sideDrawerIcon: "#E1E1E1",
  urlBg: "#303030",
  crossImg:
    "%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M19 8.5L12 15.5L5 8.5' stroke='white' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A",
};
