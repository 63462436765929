import * as React from "react";

const UsersSvg = (props) => {
  return (
    <svg
      width={22}
      height={18}
      viewBox="0 0 22 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M16.888 7.897a2.876 2.876 0 002.472-2.841 2.875 2.875 0 00-2.406-2.836M18.729 11.25c1.35.203 2.294.675 2.294 1.65 0 .671-.445 1.107-1.163 1.381"
        stroke={props.theme === "dark" ? "#fff" : "#262626"}
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        clipRule="evenodd"
        d="M10.887 11.664c-3.214 0-5.96.487-5.96 2.432 0 1.944 2.729 2.445 5.96 2.445 3.214 0 5.958-.482 5.958-2.428s-2.727-2.45-5.958-2.45zM10.887 8.888a3.819 3.819 0 10-3.82-3.819 3.804 3.804 0 003.792 3.819h.028z"
        stroke={props.theme === "dark" ? "#fff" : "#262626"}
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.885 7.897a2.875 2.875 0 01-2.472-2.841A2.875 2.875 0 014.82 2.22M3.044 11.25C1.693 11.454.75 11.926.75 12.9c0 .671.444 1.107 1.162 1.381"
        stroke={props.theme === "dark" ? "#fff" : "#262626"}
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default UsersSvg;
