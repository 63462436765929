import axios from "axios";
require("dotenv").config();

axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;
axios.defaults.headers.common["Content-Type"] = "application/json";
axios.defaults.headers.common.Accept = "application/json";
axios.defaults.timeout = 15000;

axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    // console.log("interceptor response error", error);
    return Promise.reject(error);
  }
);

axios.interceptors.request.use(
  function (response) {
    return response;
  },
  function (error) {
    // console.log("interceptor request error", error);
    return Promise.reject(error);
  }
);

const AxiosService = function () {
  let Authorization = null;

  function addHeaders(userConfig) {
    const { params, headers, timeout, ...restConfigs } = userConfig;
    let globalHeaders = {};

    if (Authorization) {
      globalHeaders.Authorization = `Token ${Authorization}`;
    }

    const { filter, ...restParams } = params || {};

    return {
      ...restConfigs,
      headers: {
        ...globalHeaders,
        ...headers,
      },
      params: {
        ...restParams,
      },
      timeout,
    };
  }

  function getAuthorizationToken() {
    return Authorization;
  }

  function setAuthorizationToken(token) {
    Authorization = token;
  }

  function resetAuthorizationToken() {
    Authorization = null;
  }

  function get(endPoint, userConfig = {}) {
    return axios.get(endPoint, addHeaders(userConfig));
  }

  function post(endPoint, params = {}, userConfig = {}) {
    return axios.post(endPoint, params, addHeaders(userConfig));
  }

  function put(endPoint, params = {}, userConfig = {}) {
    return axios.put(endPoint, params, addHeaders(userConfig));
  }

  function remove(endPoint, params = {}, userConfig = {}) {
    return axios.delete(endPoint, { ...addHeaders(userConfig), data: params });
  }

  return {
    setAuthorizationToken,
    getAuthorizationToken,
    resetAuthorizationToken,
    get,
    post,
    put,
    remove,
  };
};

export default AxiosService();
