import { combineReducers } from "redux";
import App from "./app.reducer";
import { connectRouter } from "connected-react-router";

const createReducer = (history) => {
  const appReducer = combineReducers({
    App,
    router: connectRouter(history),
  });
  return appReducer;
};

export default createReducer;
