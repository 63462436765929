import appActions from "../constants/action-types/app.actionTypes";
const initialState = {
  getClipDetailsLoading: false,
  getClipDetailsSuccess: null,
  getClipDetailsFail: false,

  themeMode: "dark",
};

const AppReducer = (state = initialState, action) => {
  switch (action.type) {
    case appActions.GET_CLIP_DETAILS.START:
      return {
        ...state,
        getClipDetailsLoading: true,
        getClipDetailsFail: false,
        getClipDetailsSuccess: null,
      };
    case appActions.GET_CLIP_DETAILS.SUCCESS:
      return {
        ...state,
        getClipDetailsLoading: false,
        getClipDetailsSuccess: action.payload,
      };
    case appActions.GET_CLIP_DETAILS.FAIL:
      return {
        ...state,
        getClipDetailsFail: !state.getClipDetailsFail,
        getClipDetailsLoading: false,
      };
    case appActions.RESET_CLIP_DETAILS:
      return {
        ...state,
        getClipDetailsLoading: false,
        getClipDetailsSuccess: null,
        getClipDetailsFail: false,
      };

    case appActions.CHANGE_THEME:
      return {
        ...state,
        themeMode: action.payload,
      };

    default:
      return state;
  }
};

export default AppReducer;
