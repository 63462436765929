import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Button, FormControl, InputGroup } from "react-bootstrap";
import "../../styles/globalStyles";
import { TwitchSvg, CrossSvg, DownloadIconSvg } from "../../assets/svg";

const EnterUrl = (props) => {
  return (
    <div>
      <h1 className="text space title-text">Clipzhub</h1>
      <p className="text subTitle-text">
        the easiest way to download twitch.tv clips
      </p>
      <InputGroup className="mb-3 center" id="input-container">
        <TwitchSvg className="input-svg" theme={props.theme} />
        <div className="seperator" />
        <FormControl
          className="placeholderText"
          onPaste={(e) => props.onPaste(e.clipboardData.getData("text"))}
          placeholder={props.placeholder}
          value={props.value}
          onChange={props.onChange}
        />
        <div onClick={props.removeText}>
          <CrossSvg className="input-cross-svg" theme={props.theme} />
        </div>
        <Button
          disabled={props.disableDownloadBtn}
          onClick={() => props.onClick(props.value)}
          className="input-download-btn"
          style={
            props.disableDownloadBtn
              ? { background: "linear-gradient(grey, grey)" }
              : {}
          }
        >
          <DownloadIconSvg className="btn-text-icon" />
          <span className="btn-text-download">download</span>
        </Button>
      </InputGroup>
    </div>
  );
};
export default EnterUrl;
