import ReactGA from "react-ga4";

export const twitchLinkSearchEvent = () => {
  ReactGA.event({
    category: "Twitch",
    action: "search",
    label: "Twitch link searched",
    value: 1,
  });
};

export const copyTwitchLinkEvent = () => {
  ReactGA.event({
    category: "Twitch",
    action: "copy",
    label: "Twitch link copied",
    value: 1,
  });
};

export const downloadTwitchClipEvent = () => {
  ReactGA.event({
    category: "Twitch",
    action: "download",
    label: "Twitch link downloaded",
    value: 1,
  });
};
