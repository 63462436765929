import appActions from "../constants/action-types/app.actionTypes";
import commonActions from "../constants/action-types/common";
import * as appApi from "../api/app.api";

export const getClipDetails = (data) => ({
  type: commonActions.COMMON_API_CALL,
  subtypes: appActions.GET_CLIP_DETAILS,
  promise: () => appApi.getClipDetailsApi(data),
});

export const resetClipDetails = () => ({
  type: appActions.RESET_CLIP_DETAILS,
});

export const changeTheme = (theme) => ({
  type: appActions.CHANGE_THEME,
  payload: theme,
});
