export const FAQ_DATA = [
  {
    headingKey: 1,
    collapseKey: "collapseOne",
    question: "Is Clipzhub free to use?",
    answer: (
      <p>
        Absolutely, we don’t believe in hogging money from our users for the
        simplest of services, as other platforms do and our online twitch clip
        saver aka Clipzhub is absolutely free and there are no hidden charges.
        We believe morally everyone has a right to entertainment free of cost
        and we pledge to entertain you and serve you for a lifetime!
        <br />
        <br />
        We do need however to run the website and running ads every now and then
        helps us achieve that. Well, this is a win-win situation, you get the
        service for free, we get to serve you and brands get to promote
        themselves at our very own platform Clipzhub the best twitch clip
        downloader in 2021!
        <br />
        <br />
        Now you can download as many Twitch clips you wish to through the day
        and night, stress-free and cost-free using our very own platform
        Clipzhub the only Twitch clip downloader you want. Having said that, go
        check out our auto-downloading features and get started with your first
        free offline Twitch clip if you haven’t yet!
      </p>
    ),
  },
  {
    headingKey: 2,
    collapseKey: "collapseTwo",
    question: "Why am I unable to download the Twitch clip?",
    answer: (
      <p>
        1. There can be numerous reasons why you are unable to download the
        Twitch clip, but mainly this happens when the link that you pasted would
        be wrong. To get the right URL for offline Twitch clip, paste the URL in
        the search bar in either of the two formats:
        <br />
        <br />
        &nbsp;&nbsp;&nbsp;a.
        https://clips.twitch.tv/DownloadTwitchclipsFromClipzhub
        <br />
        <br />
        &nbsp;&nbsp;&nbsp;b.
        https://www.twitch.tv/Clipzhub/clip/TheBestTwitchClipDownloaderof2021
        <br />
        <br />
        2. Another reason that you are unable to download the Twitch clip from
        our online Twitch clip saver is that you might have added a blank space
        in the URL by mistake. Try to see if there’s any space in the Twitch
        clip URL and remove it, you’ll be able to download the online Twitch
        clip in no time.
        <br />
        <br />
        3. Sometimes there have been cases when Twitch.Tv might have deleted the
        clip that you want to download and that is why the downloading fails.
        Check the clip on the main platform whether it has been deleted or not.
        <br />
        <br />
        4. You can also check your network, sometimes due to connectivity
        issues, you facing download issues.
        <br />
        <br />
        5. In other scenarios, due to the presence of a third-party extension
        you face downloading issues, the extension might interfere with the
        downloading process and you might need to check on that.
        <br />
        <br />
        6. Sometimes due to server overload, the downloading fails and you won’t
        get to download the online Twitch clip. You might have to wait for some
        time and then try again.
        <br />
        <br />
        7. There have been times when the Twitch clip download fails due to
        Twitch API issues.
        <br />
        <br />
        8. The least case scenario is when Twitch.Tv updates its API and our
        developers would be still working on it and so you are unable to
        download the Twitch clip using our online Twitch clip saver. In such
        cases, we try releasing the updated version and making it live as soon
        as possible keeping in mind, providing a seamless and speedy downloading
        experience for you.
      </p>
    ),
  },
  {
    headingKey: 3,
    collapseKey: "collapseThree",
    question: "Why is it showing an error?",
    answer: (
      <p>
        An error shows when your Twitch clip URL is invalid and you need to
        check whether either of the two formats has been used for downloading
        the desired Twitch clip. We understand that it can be frustrating to not
        know what’s happening, and Clipzhub has been designed keeping these
        minute details and concerns. You will get a pop-up message from us
        saying “Invalid URL” or “Error” so that you know when there’s an
        issue/error.
      </p>
    ),
  },
  {
    headingKey: 4,
    collapseKey: "collapseFour",
    question: "Does the Twitch clip resolution get affected due to download?",
    answer: (
      <p>
        Not at all. Well, it’s not the 60s anymore and no one should compromise
        with the video quality. We know what it’s like to watch an HD video in
        240p and we can’t do that to you, this practice is morally immoral and
        we refrain from compromising the quality of the Twitch clip while
        downloading it for you. Besides Clipzhub also provides you with an
        option of downloading the desired quality of a clip if the option is
        originally available. You can download the highest quality available
        originally in Twitch.Tv or any quality below that.
      </p>
    ),
  },
  {
    headingKey: 5,
    collapseKey: "collapseFive",
    question: "Do we have a chrome extension? ",
    answer: (
      <p>
        We are just starting out and if you support us and demand the extension
        we will definitely plan a chrome extension of our online Twitch clip
        downloader. But currently, we do not have an extension.
      </p>
    ),
  },
  {
    headingKey: 6,
    collapseKey: "collapseSix",
    question: "How do I switch to light mode?",
    answer: (
      <p>
        On your top left corner, you can see a Sun icon, by clicking on that you
        get to switch to light mode. Similarly, you can click on the crescent
        moon icon and switch to dark mode in a fraction of a second!
        <br />
        <br />
        Switching between dark mode and light mode is more than a choice in the
        current Internet world and we understand that. We have added every
        minute detail in our design to make the user experience easy and smooth
        keeping in mind what you want and what you should get but isn’t provided
        by any other platform.
      </p>
    ),
  },
  {
    headingKey: 7,
    collapseKey: "collapseSeven",
    question: "Is it safe to download twitch clips from Clipzhub?",
    answer: (
      <p>
        Absolutely, Clipzhub provides a platform to a huge audience enabling
        them to download any Twitch clip for their personal use which can be to
        just watch the Twitch clip offline or save it in your own created
        offline playlist or any other personal use. However, if you want to use
        any Twitch clip for commercial use, professional use, streaming purposes
        or sharing the Twitch clip with a wide range of audiences, you need to
        take permission from the Twitch clip owner. If you fail to do so, you
        infringe the intellectual property rights of the owner and you might get
        in some serious trouble.
        <br />
        <br />
        We highly recommend you go through the guidelines and terms and policies
        provided by Twitch.Tv before using any other streamers’ Twitch clips or
        any other content that does not align with Twitch.Tv’s guidelines.
      </p>
    ),
  },
  {
    headingKey: 8,
    collapseKey: "collapseEight",
    question: "Can I also download clips from other social media platforms?",
    answer: (
      <p>
        Currently, we are only running Twitch clip downloader but we soon going
        to launch an online content downloader for tons of social media
        platforms such as Youtube, Reddit, Facebook, VK, Instagram, Twitter and
        many more. We will keep you notified for the same!
      </p>
    ),
  },
  {
    headingKey: 9,
    collapseKey: "collapseNine",
    question: "Can I use Clipzhub to download Twitch clips in Phone?",
    answer: (
      <p>
        Absolutely. Our designers have specially designed a Mobile mode for you
        so that your Twitch clip downloading experience is smooth, portable and
        device friendly. Our mobile mode supports all mobile devices and your
        experience is ensured to be uncompromising.
      </p>
    ),
  },
  {
    headingKey: 10,
    collapseKey: "collapseTen",
    question: "What is the format of saving the Twitch clips?",
    answer: (
      <p>
        Clipzhub provides you with the MP4 format to download any Twitch clip.
      </p>
    ),
  },
  {
    headingKey: 11,
    collapseKey: "collapseEleven",
    question: "Why is the downloaded Twitch clip not playing?",
    answer: (
      <p>
        This might happen when your local media player is different from the MP4
        format. Change the format to MP4 and you should be able to play the
        downloaded file.
        <br />
        <br />
        Sometimes there might be some issue with the Slug and the downloaded
        file doesn’t play, in such case, you should try to download the same
        clip again.
        <br />
        <br />
        In a rare scenario, there might be a bug because of which the downloaded
        Twitch clip is not playing, in such case, the bug is fixed as soon as it
        gets detected and this issue should not occur again. In case you still
        face this issue, you can contact us and we will get into action right
        away.
      </p>
    ),
  },
  {
    headingKey: 12,
    collapseKey: "collapseTwelve",
    question: "Where are downloaded Twitch clips getting saved?",
    answer: (
      <p>
        Every downloaded Twitch clip gets saved in the default downloads section
        in the user system. If you are unable to find the downloaded Twitch
        clip, check your browser settings and see your default download
        location, that’s where you’ll find the offline clip.
      </p>
    ),
  },
  {
    headingKey: 13,
    collapseKey: "collapseThirteen",
    question: "Is Clipzhub affiliated with Twitch.tv?",
    answer: (
      <p>
        In no way Clipzhub is affiliated with Twitch.Tv, we are in fact here to
        help you download and make your favourite Twitch clip offline absolutely
        free of cost without any hidden charges.
      </p>
    ),
  },
  {
    headingKey: 14,
    collapseKey: "collapseFourteen",
    question: "Does Clipzhub collect and save my personal information?",
    answer: (
      <p>
        No, we understand that it is difficult to build trust in this pretence
        internet world and so we want to keep things simple and secure for our
        users. Clipzhub does not save any personal information thus making your
        twitch clip download experience trustworthy.
      </p>
    ),
  },
];
