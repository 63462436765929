import * as React from "react";

const TwitchSvg = (props) => {
  return (
    <svg
      width={30}
      height={32}
      viewBox="0 0 34 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M2.319 0L0 6.261v25.041h8.498V36h4.64l4.634-4.698h6.954L34 21.915V0H2.319zm3.089 3.129H30.91v17.217l-5.411 5.48H17l-4.633 4.69v-4.69h-6.96V3.128zm8.5 15.654H17v-9.39h-3.091v9.39zm8.5 0H25.5v-9.39h-3.09v9.39z"
        fill={props.theme === "dark" ? "#F7F7F7" : "#1B1B1B"}
      />
    </svg>
  );
};

export default TwitchSvg;
