import React, { useEffect, useState } from "react";
import {
  getClipDetails,
  resetClipDetails,
} from "../../redux/actions/app.actions";
import { connect } from "react-redux";
import {
  EnterUrl,
  Cards,
  DropDown,
  SearchData,
  Loader,
  ErrorAlert,
} from "../../components";
import { HOME_CONTENT } from "./home_content";
import {
  twitchLinkSearchEvent,
  downloadTwitchClipEvent,
} from "../../googleAnalytics";
const HomeScreen = (props) => {
  const [clipDetails, setclipDetails] = useState(null);
  const [clipUrl, setClipUrl] = useState("");

  useEffect(() => {
    if (props.getClipDetailsSuccess) {
      setclipDetails(props.getClipDetailsSuccess);
    }
  }, [props.getClipDetailsSuccess, props.getClipDetailsFail]);

  const onPaste = (pastedItem) => {
    if (pastedItem && pastedItem.includes("twitch")) {
      getClipDetails(pastedItem);
    }
  };

  const getClipDetails = (pastedItem) => {
    props.resetClipDetails();
    setclipDetails(null);
    let data = {
      clipUrl: pastedItem ? pastedItem : clipUrl,
    };
    twitchLinkSearchEvent();
    props.getClipDetails(data);
  };

  return (
    <div>
      <EnterUrl
        disableDownloadBtn={clipUrl.length === 0 || props.getClipDetailsLoading}
        onPaste={onPaste}
        onClick={getClipDetails}
        placeholder="https://clips.twitch.tv/DownloadTwitchclipsFromClipzhub"
        value={clipUrl}
        onChange={(event) => setClipUrl(event.target.value)}
        isDownloadEnabled={clipDetails && clipDetails.downloadUrl}
        onDownloadClick={(e) => {
          downloadTwitchClipEvent();
          e.preventDefault();
          window.location.href = clipDetails.downloadUrl;
        }}
        isLoaderEnabled={props.getClipDetailsLoading}
        theme={props.themeMode}
        removeText={() => setClipUrl("")}
      />
      {props.getClipDetailsLoading ? <Loader /> : null}
      {props.getClipDetailsFail ? <ErrorAlert /> : null}
      {clipDetails ? (
        <SearchData
          clipDetails={clipDetails}
          onDownloadClick={(e) => {
            downloadTwitchClipEvent();
            e.preventDefault();
            window.location.href = clipDetails.downloadUrl;
          }}
        />
      ) : null}
      <Cards theme={props.themeMode} />
      <div class="container my-5">
        {HOME_CONTENT.map((item) => (
          <DropDown
            question={item.question}
            answer={item.answer}
            headingKey={item.headingKey}
            collapseKey={item.collapseKey}
          />
        ))}
      </div>
    </div>
  );
};

const mapStateToProps = ({ App }) => {
  const {
    getClipDetailsLoading,
    getClipDetailsSuccess,
    getClipDetailsFail,
    themeMode,
  } = App;
  return {
    getClipDetailsLoading,
    getClipDetailsSuccess,
    getClipDetailsFail,
    themeMode,
  };
};

const mapDispatchToProps = {
  getClipDetails,
  resetClipDetails,
};

export default connect(mapStateToProps, mapDispatchToProps)(HomeScreen);
