import React from "react";
import Lottie from "lottie-react";
import { LoaderLottie } from "../../assets/animations";

const Loader = () => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div style={{ height: 210, width: 210 }}>
        <Lottie animationData={LoaderLottie} loop autoplay />
      </div>
    </div>
  );
};

export default Loader;
