import React from "react";
import { logo } from "../../assets/images";
import { HiddenContent } from "../../assets/seo-content/hiddenContent";
import "./footer.css";

const Footer = () => {
  return (
    <footer class="footer-59391">
      <div class="row align-items-center mx-5">
        <div class="col-md-4 text-md-center site-logo order-1 order-md-2 mb-3 mb-md-0 d-flex justify-content-center">
          <a href="#" class="m-0 p-0">
            <img src={logo} alt="Clipzhub logo" />
          </a>
        </div>
        <div class="col-md-4 text-md-right order-3 order-md-3 d-flex justify-content-center">
          <ul class="list-unstyled nav-links m-0">
            <li>
              <a href="/privacy-policy" to="/privacy-policy">
                Privacy Policy
              </a>
            </li>
            <li>
              <a href="/terms" to="/terms">
                Terms
              </a>
            </li>
          </ul>
        </div>

        <div class="col-md-4 order-2 order-md-1 mb-3 mb-md-0 d-flex justify-content-center">
          <p class="m-0 text-muted">
            <small>&copy; 2021. All Rights Reserved.</small>
          </p>
        </div>
      </div>
      <HiddenContent />
    </footer>
  );
};

export default Footer;
