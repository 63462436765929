import React, { useState, useEffect } from "react";

import { Nav, Navbar, Container } from "react-bootstrap";
import { Toggle, SideDrawer, ToolBar, BackDrop } from "../../components";
import { connect } from "react-redux";
import { changeTheme } from "../../redux/actions/app.actions";
import { logo } from "../../assets/images";

const Header = ({ theme, toggleTheme, changeTheme }) => {
  const [sideDrawerOpen, setsideDrawerOpen] = useState(false);
  let backDrop;

  const drawerToggleClickHandler = () => {
    setsideDrawerOpen(!sideDrawerOpen);
  };

  const closeSideMenu = () => {
    setsideDrawerOpen(false);
  };

  useEffect(() => {
    changeTheme(theme);
  }, [theme]);

  if (sideDrawerOpen) {
    backDrop = <BackDrop click={closeSideMenu} />;
  }
  return (
    <Navbar className="py-3 px-2" collapseOnSelect expand="md">
      <Container>
        <Navbar.Brand href="/" to="/">
          <img src={logo} alt="Clipzhub logo" />
        </Navbar.Brand>
        <ToolBar
          drawerClickHandler={drawerToggleClickHandler}
          theme={theme}
          toggleTheme={toggleTheme}
        />
        <SideDrawer
          show={sideDrawerOpen}
          closeSideMenu={closeSideMenu}
          theme={theme}
        />
        {backDrop}
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto" />

          <Nav className="d-flex flex-row align-items-center">
            <Toggle theme={theme} toggleTheme={toggleTheme} />
            <div className="mx-2" />
            <Nav.Link href="/" to="/">
              home
            </Nav.Link>
            <Nav.Link href="/about" to="/about">
              about us
            </Nav.Link>
            <Nav.Link href="/faq" to="/faq">
              faq
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

const mapDispatchToProps = {
  changeTheme,
};

export default connect(null, mapDispatchToProps)(Header);
